import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/317f3503/src/components/layouts/index.js";
import TypographyScale from "../components/TypographyScale";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Typography`}</h1>
    <p>{`Typography is at the center of good interface design and this documentation provides some pointers on how to use the typographic elements within DS-kit effectively when building datastory products.`}</p>
    <h2>{`The Typeface`}</h2>
    <p>{`The main typeface used across the datastory platform is `}<inlineCode parentName="p">{`Averta PE`}</inlineCode>{`. Averta is a geometric sans serif with true italics and supports 250+ languages.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f3121ae9dc0b9ce736c20d9c4ebd5da4/39282/averta-cover.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.224489795918366%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACY0lEQVQoz32TX0hTYRTALwlR0Eu99AeCBhHUoy++hARlPtkii0xJQqGHGCpJRJBlkD1kIPhQ1NhTZfZQafaSkUQGQl7NdOruqrs5t+k0ddvdnG5399f9bo5mSQcO3zmH7/udc77vfBIbiGEYOWvNNvAmDKJpI28PG4qUDxBrOp2xAL4knJLFauAKGEhdBvv7DNp9sKpnLaI4ZvxFlvJheTXSN6ez822WCjlLwWudhjGd24rOlp4MoczvXelMBt3UfIYFzAXmIxGcbW3k0I3fwP4Frvrh7jTUm/6rBMz6VVZWVja8JgsosgjpcDopstmIqF4evpPZ3PaZTS6VAy1dFNz5gNTykeZHz6k+eoTenjf0dnfT6XIRXVpaX6GQRDzOxdPlXLDbefGgneLaa2yvusmhohJsRaXYyuuwHbZTWHWZK7U1tDY1cd3h4FzJMSZHRy1GNptF0nXdcp6ZmS5VnKW+uppbdQ7KSsvYe/w8u8sbOFhYzJ66++woMwG9QRrPnKS1uRlHZSU19hNEwuE/wFyFPxSFjNm6CE75p/CPDjPsUbH1w9Z7n5CcKvs6AwRXYXJokHAwxNDAAN6JifWPIgxR5XIyyUwwSHh6mp+RWeZmZnisxNj2JIjUEWfXywWeyipaOIAWi7EwP8dsKGRpKpVaDxRVhQIBvsqyldHtHkceGaOpP8CN9wrdgx483u/4JydQ1AAjbg9TPh/e8XEUtxvNvP9/HiVfzK5IikRi1tb8xFpMMzX6n99lVRiPRllaXLQyiflaTiRIJROmHyOpxa0JELFlTbM0JWKWatYZoaJLIb8A5r1DTbtmE34AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Averta cover",
            "title": "Averta cover",
            "src": "/static/f3121ae9dc0b9ce736c20d9c4ebd5da4/f570d/averta-cover.png",
            "srcSet": ["/static/f3121ae9dc0b9ce736c20d9c4ebd5da4/0783d/averta-cover.png 240w", "/static/f3121ae9dc0b9ce736c20d9c4ebd5da4/782f4/averta-cover.png 480w", "/static/f3121ae9dc0b9ce736c20d9c4ebd5da4/f570d/averta-cover.png 960w", "/static/f3121ae9dc0b9ce736c20d9c4ebd5da4/39282/averta-cover.png 980w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`The Scale`}</h2>
    <p>{`Typographic scales are used to create a consistent hierarchy when designing with different font sizes. Heading and text use the same type scale, which ranges from `}<inlineCode parentName="p">{`xxs`}</inlineCode>{` to `}<inlineCode parentName="p">{`xxxl`}</inlineCode>{`. Note that headings have a tighter line height than text by default.`}</p>
    <TypographyScale word="Heading" variant="headings" mdxType="TypographyScale" />
    <a href="/" style={{
      "display": "none"
    }}>
  See the samples in the documentation
    </a>
    <TypographyScale word="Text" variant="text" mdxType="TypographyScale" />
    <a href="/" style={{
      "display": "none"
    }}>
  See the samples in the documentation
    </a>
    <h2>{`Text Colors`}</h2>
    <p>{`When using colors on text, make sure to follow the a11y guidelines on color contrast to ensure that all users can easily read and understand the text.`}</p>
    <p>{`We encourage the use of neutral text colors instead of using colored text. The basic text color is `}<inlineCode parentName="p">{`text`}</inlineCode>{`, which is the equivalent to `}<inlineCode parentName="p">{`grey.900`}</inlineCode>{`.`}</p>
    <p>{`In case you are using vibrant background colors, it may be worth setting the text in a very light shade of the same color. If you are using `}<inlineCode parentName="p">{`purple.500`}</inlineCode>{` for a background, you could use `}<inlineCode parentName="p">{`purple.50`}</inlineCode>{` as a text color.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      