import "core-js/modules/es6.regexp.split";
import React, { useRef, useState, useEffect } from "react";
import { Span, P, Heading } from "../../../packages/Typography/src";
var headingSizes = ["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"];
var textSizes = ["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"];

var HeadingItem = function HeadingItem(_ref) {
  var word = _ref.word,
      size = _ref.size;
  var heading = useRef(null);

  var _useState = useState(0),
      sizePx = _useState[0],
      setSizePx = _useState[1];

  var _useState2 = useState(0),
      lhPx = _useState2[0],
      setLhPx = _useState2[1];

  var handleResize = function handleResize() {
    var h1 = heading.current.getElementsByTagName("h1")[0];
    setSizePx(window.getComputedStyle(h1).fontSize);
    setLhPx(Math.round(window.getComputedStyle(h1).lineHeight.split("px")[0]) + "px");
  };

  useEffect(function () {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function () {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return React.createElement("div", {
    ref: heading
  }, React.createElement(Heading, {
    size: size,
    mt: "1.25rem",
    mb: "0"
  }, word), React.createElement(Span, {
    fontSize: 2,
    bg: "grey.25",
    px: 2
  }, size + " \u2014 " + sizePx + " / " + lhPx));
};

var HeadingScale = function HeadingScale(_ref2) {
  var word = _ref2.word;
  return React.createElement("div", {
    style: {
      borderTop: "0.0625rem solid #DDD",
      borderBottom: "0.0625rem solid #DDD",
      paddingBottom: "1.5rem"
    }
  }, headingSizes.map(function (size) {
    return React.createElement(HeadingItem, {
      key: "heading-" + size,
      size: size,
      word: word
    });
  }));
};

var TextItem = function TextItem(_ref3) {
  var word = _ref3.word,
      size = _ref3.size;
  var textEl = useRef(null);

  var _useState3 = useState(0),
      sizePx = _useState3[0],
      setSizePx = _useState3[1];

  var _useState4 = useState(0),
      lhPx = _useState4[0],
      setLhPx = _useState4[1];

  var handleResize = function handleResize() {
    var p = textEl.current.getElementsByTagName("p")[0];
    setSizePx(window.getComputedStyle(p).fontSize);
    setLhPx(Math.round(window.getComputedStyle(p).lineHeight.split("px")[0]) + "px");
  };

  useEffect(function () {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function () {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return React.createElement("div", {
    ref: textEl
  }, React.createElement(P, {
    size: size,
    mt: 3,
    mb: 0
  }, word), React.createElement(Span, {
    fontSize: 2,
    bg: "grey.25",
    px: 2
  }, size + " \u2014 " + sizePx + " / " + lhPx));
};

var TextScale = function TextScale(_ref4) {
  var word = _ref4.word;
  return React.createElement("div", {
    style: {
      borderTop: "0.0625rem solid #DDD",
      borderBottom: "0.0625rem solid #DDD",
      paddingBottom: "1.5rem"
    }
  }, textSizes.map(function (size) {
    return React.createElement(TextItem, {
      key: "text-" + size,
      word: word,
      size: size
    });
  }));
};

var scales = {
  headings: HeadingScale,
  text: TextScale
};

var TypographyScale = function TypographyScale(_ref5) {
  var _ref5$variant = _ref5.variant,
      variant = _ref5$variant === void 0 ? "headings" : _ref5$variant,
      _ref5$word = _ref5.word,
      word = _ref5$word === void 0 ? "hamburgefontsiv" : _ref5$word;
  var Comp = scales[variant];
  return React.createElement(Comp, {
    word: word
  });
};

export default TypographyScale;